/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100vh;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: calc(100vh - 64px);
}

qbit-table-filters > div > b {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
