$textDark: #494949;
$textMid: #757575;
$textLight: #a4a4a4;
$bg: #f1f1f1;
$sLight: #a4a4a4;
$border: #ddd;

$colGreen: #66bb6a;
$colBlue: #42a5f5;
$colRed: #ef5350;
$colPurple: #5741d9;

$borderColor: rgba(0, 0, 0, 0.12);

$bgBlueLight: #e7f3fe;
$bgGreenLight: #edf7ee;
$bgPurpleLight: #f5edf7;
$bgRedLight: #f7eded;
